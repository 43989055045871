import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import Carousel from "react-elastic-carousel"
import Layout from "./Layout"
import { graphql, Link } from "gatsby"
import { Button } from "./Button"
import { StaticImage } from "gatsby-plugin-image"
import { MPLarge, RobotoNormal2 } from "../styles/PageStyles"
import { SEO as Seo } from "./SEO"

const CareersTemplate = ({ data }) => {
  const { html } = data.markdownRemark
  const { title, description, slug } = data.markdownRemark.frontmatter

  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1024, itemsToShow: 3 },
  ]

  return (
    <Layout>
      <Seo
        title={"Blackfort - " + title}
        description={description}
        url={"https://www.blackfort.ph/careers/" + slug}
      />
      <Container fluid>
        <StyledRow>
          {/* MARKDOWN GRAPHQL */}
          <StyledCol lg={12} data-aos="fade-right">
            <CareerPosition>
              <MPLarge style={{ color: "#FFB700" }}>{title}</MPLarge>
              <Button
                className="apply-btn"
                round
                primary
                href="https://forms.gle/eCtaYgsA1DdHPxKz5"
                target="_blank"
              >
                Drop your CV
              </Button>
            </CareerPosition>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </StyledCol>

          <StyledCol lg={12} data-aos="zoom-in" data-aos-duration="300">
            <h4>
              Drop us your CV or Portfolio! We always say hi to great people.
            </h4>
          </StyledCol>

          <StyledCol>
            <Link className="link-to-careers" to="/careers">
              <span>Back to Careers</span>
            </Link>
          </StyledCol>

          {/* CAROUSEL */}
          <StyledCarousel
            breakPoints={breakPoints}
            enableAutoPlay
            autoPlaySpeed={5000}
          >
            <CarouselItem>
              <StaticImage
                className="CarouselImage"
                draggable="false"
                src="./multimedia/Business-Dinner-2.jpg"
                alt="Career 1"
              />
              <Overlay>
                <RobotoNormal2>
                  Exchanging ideas over a good meal always gets the best
                  results!
                </RobotoNormal2>
              </Overlay>
            </CarouselItem>
            <CarouselItem>
              <StaticImage
                className="CarouselImage"
                draggable="false"
                src="./multimedia/Contract-Renewal-2.jpg"
                alt="Career 2"
              />
              <Overlay>
                <RobotoNormal2>
                  Here at BlackfortPH, we value each client as an important part
                  of our family and network. Each team member is recognized for
                  their efforts as we celebrate another contract renewed!
                </RobotoNormal2>
              </Overlay>
            </CarouselItem>
            <CarouselItem>
              <StaticImage
                className="CarouselImage"
                draggable="false"
                src="./multimedia/Contract-Renewal-1.jpg"
                alt="Career 3"
              />
              <Overlay>
                <RobotoNormal2>
                  Their smiles reflect the dedication and hardwork we bring into
                  each of our projects, and we are proud to say that all of our
                  clients are extremely satisfied with the results we provide!
                </RobotoNormal2>
              </Overlay>
            </CarouselItem>
            <CarouselItem>
              <StaticImage
                className="CarouselImage"
                draggable="false"
                src="./multimedia/Team-Lunch.jpg"
                alt="Career 4"
              />
              <Overlay>
                <RobotoNormal2>
                  Here at Blackfort, we always make time for a little R&R, even
                  if it's just over lunch!
                </RobotoNormal2>
              </Overlay>
            </CarouselItem>
            <CarouselItem>
              <StaticImage
                className="CarouselImage"
                draggable="false"
                src="./multimedia/Business-Dinner.jpg"
                alt="Career 5"
              />
              <Overlay>
                <RobotoNormal2>
                  Another successful business planning, made more memorable with
                  having dinner as one solid team!
                </RobotoNormal2>
              </Overlay>
            </CarouselItem>
            <CarouselItem>
              <StaticImage
                className="CarouselImage"
                draggable="false"
                src="./multimedia/Year-End-Party.jpg"
                alt="Career 6"
              />
              <Overlay>
                <RobotoNormal2>
                  Here we are, celebrating the year's milestones and sending off
                  good vibes as we welcome the incoming year together!
                </RobotoNormal2>
              </Overlay>
            </CarouselItem>
          </StyledCarousel>
        </StyledRow>
      </Container>
    </Layout>
  )
}

export default CareersTemplate

const StyledRow = styled(Row)`
  display: flex;
  padding: 10vh 10% 0 10%;

  @media screen and (max-width: 768px) {
    padding: 3vw;
  }
`

const StyledCol = styled(Col)`
  padding: 5vh 10% 5vh 10%;
  line-height: 30px;

  h1 {
    font-weight: 600;
    padding-bottom: 5vh;
    padding-top: 5vh;
  }

  h4 {
    text-align: center;
    font-weight: 600;
    color: #ffb700;
    padding-bottom: 5vh;
  }

  .link-to-careers {
    display: flex;
    justify-content: center;
    padding-bottom: 10vh;

    span {
      &:after {
        content: "";
        display: block;
        width: -0;
        height: 3px;
        background: #ffb700;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        display: block;
        color: #ffb700;
        transform: translateY(-2px);
        transition: 0.3s ease-in-out;
        &:after {
          width: 100%;
        }
      }
    }
  }
`

const CareerPosition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    text-align: center;
    flex-direction: column;

    .apply-btn {
      margin-bottom: 5vh;
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 12%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease-in-out;
  transform: translateY(20px);

  p {
    color: black;
  }
`

const CarouselItem = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffb700;

  .CarouselImage {
    transition: 0.5s ease-in-out;
  }

  &:hover {
    ${Overlay} {
      opacity: 100%;
      transform: translateY(0px);
    }
    .CarouselImage {
      opacity: 10%;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

const StyledCarousel = styled(Carousel)`
  .rec-arrow {
    display: none;
  }

  .rec-dot {
    background: #333;
    box-shadow: none;
  }

  .rec-dot_active {
    background: #ffb700;
  }
`

export const query = graphql`
  query CareersTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`